<template>
  <div class="container-fluid">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="text-center my-3">
        <h1>Activation</h1>
        <div>
          This system requires a license to activate. Follow the steps below to
          activate a license:
        </div>
      </div>

      <div class="w-50">
        <b-card title="Step 1" class="bg-surface-2">
          <b-card-text>
            Press <strong>"Download Activation Key"</strong> button below.
            <br />
          </b-card-text>

          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="downloadActivationKey"
              >Download Activation Key <i class="fas fa-key"></i
            ></b-button>
          </div>
        </b-card>

        <div class="mb-3"></div>

        <b-card title="Step 2" class="bg-surface-2">
          <b-card-text>
            Contact Entrypass Sales Team to get the
            <strong>license file</strong> by sending them the
            <strong>Activation Key.</strong>
          </b-card-text>
          <b-card-text>
            Upload the <strong>license file</strong> here, then click
            <strong>"Activate"</strong> button to activate the license.
          </b-card-text>

          <div
            class="my-1 bg-surface-1 border border-1"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
            v-if="disableActivateButton"
          >
            <input
              type="file"
              id="files"
              ref="files"
              class="d-none"
              @change="handleFilesUploads"
            />

            <label
              for="files"
              class="block cursor-pointer d-flex justify-content-center flex-column p-5"
            >
              <div class="text-center">
                <i
                  class="fas fa-cloud-upload-alt p-1 m-2 text-secondary h1"
                ></i>
              </div>
              <div class="text-center">Choose a file or drag it here</div>
            </label>
          </div>

          <div v-if="!disableActivateButton">
            <div class="d-flex justify-content-end">
              <b-button variant="link" @click="handleClearAndReselect"
                >Cancel & Reselect file</b-button
              >
            </div>

            <b-card
              bg-variant="primary"
              text-variant="white"
              title="License Uploaded"
            >
              <b-card-text>
                Activate your license to use Smart Canopy.
              </b-card-text>
            </b-card>
          </div>

          <div class="my-4"></div>
          <b-button
            variant="primary"
            :disabled="disableActivateButton"
            @click="handleClickActivateButton"
            >Activate</b-button
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

let utils = require("@/assets/global/js/utils.js");
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import moment from "moment";
import store from "../../store";

const FileDownload = require("js-file-download");

export default {
  data() {
    return {
      apiserver_url: null,
      disableActivateButton: true,
      files: []
    };
  },
  mounted() {
    this.setupApiserverUrl();
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  methods: {
    setupApiserverUrl() {
      let apiserver_url = "";

      if (
        process.env.VUE_APP_APISERVER !== undefined &&
        process.env.VUE_APP_APISERVER !== null &&
        process.env.VUE_APP_APISERVER !== ""
      ) {
        apiserver_url = process.env.VUE_APP_APISERVER;
      } else {
        if (
          process.env.VUE_APP_APISERVER_USE_PORT !== undefined &&
          process.env.VUE_APP_APISERVER_USE_PORT !== null &&
          process.env.VUE_APP_APISERVER_USE_PORT === "true"
        ) {
          apiserver_url =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":8000";
        } else {
          apiserver_url =
            window.location.protocol + "//" + window.location.hostname + "/0";
        }
      }

      this.apiserver_url = apiserver_url;
    },
    downloadActivationKey() {
      let $this = this;

      const API_URL = this.apiserver_url + "/license/activation-key/";
      const client = new AjaxFetch({ responseType: "blob" }, this);

      client
        .getRequest(API_URL)
        .then((data) => {
          FileDownload(data, "SC_ACTIVATION_KEY");
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-secondary")) {
        event.currentTarget.classList.remove("bg-dark");
        event.currentTarget.classList.add("bg-secondary");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-dark");
      event.currentTarget.classList.remove("bg-secondary");
    },
    drop(event) {
      event.preventDefault();

      // console.log("event.dataTransfer.files");
      // console.log(event.dataTransfer.files);

      this.$refs.files.files = event.dataTransfer.files;

      this.handleFilesUploads(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-dark");
      event.currentTarget.classList.remove("bg-secondary");
    },

    async handleFilesUploads() {
      // if (this.$refs.files.files.length > 0) {
      //   if (this.$refs.files.files[0]["name"] === "SC_LICENSE") {
      //     this.files = this.$refs.files.files;
      //     this.disableActivateButton = false;
      //   } else {
      //     await this.$store.dispatch("session/addGlobalAlertMessage", {
      //       message_text: 'Please select a valid "SC_LICENSE" file',
      //       message_type: "danger"
      //     });
      //   }
      // }

      if (this.$refs.files.files.length > 0) {
        this.files = this.$refs.files.files;
        this.disableActivateButton = false;
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: "Success upload license file.",
          message_type: "success"
        });
      }
    },
    handleClickActivateButton() {
      let formData = new FormData();

      let file = this.files[0];
      formData.append("license_file", file);

      let $this = this;

      const API_URL = this.apiserver_url + "/license/";
      const client = new AjaxFetch(null, this);

      client
        .postRequest(API_URL, formData)
        .then((data) => {
          // console.log("success upload license, data");
          // console.log(data);

          this.verifyLicense();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    async verifyLicense() {
      let $this = this;

      const API_URL = this.apiserver_url + "/license/verify/";
      const client = new AjaxFetch(null, this);

      let data = await client.postRequest(API_URL);

      // {
      //   "result": {
      //   "http://127.0.0.1:8001": {
      //       "result": true
      //     }
      //   }
      // }

      // console.log("verifyLicense data", data);

      if (data.result !== null) {
        for (const url in data.result) {
          // console.log("url");
          // console.log(url);
          //
          // console.log("data['result'][url]['result']");
          // console.log(data["result"][url]["result"]);

          if (data["result"][url]["result"]) {
            // console.log("verify exp data...");

            /// verify exp data
            var API_URL2 = this.apiserver_url + "/license/info/";
            client
              .getRequest(API_URL2)
              .then((data) => {
                let result = data.result;

                // console.log("verify exp data result");
                // console.log(result);

                for (const url in result) {
                  if (result[url] != null) {
                    let exp = moment(result[url]["exp"]);
                    let now = moment();

                    let diffSec = exp.diff(now);

                    if (diffSec > 0) {
                      $this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Success activate Smart Canopy",
                        message_type: "success"
                      });

                      $this.$router.push({ name: "login" }).catch(() => {});
                    } else {
                      $this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text:
                          "Your license is expired. You must renew your license to use Smart Canopy. Thank you",
                        message_type: "danger"
                      });

                      this.handleClearAndReselect();
                    }
                  }
                }
              })
              .catch((err) => {
                // console.log("err");
                // console.log(err);

                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Smart Canopy activation fail!",
                  message_type: "danger"
                });

                this.handleClearAndReselect();
              });
          } else {
            await $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Smart Canopy activation fail!",
              message_type: "danger"
            });

            this.handleClearAndReselect();
          }
        }
      } else {
        await $this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: "Smart Canopy activation fail!",
          message_type: "danger"
        });
        this.handleClearAndReselect();
      }
    },
    handleClearAndReselect() {
      this.disableActivateButton = true;
      this.files = [];
    }
  }
};
</script>
